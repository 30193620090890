.radio {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}


.radio input:disabled~.radio-checkmark {
   cursor: default;
}

.radio input:checked~.radio-circle {
   display: block;
}
