@font-face {
  font-family: 'ams-icon';
  src:  url('fonts/ams-icon.eot?fc6hvd');
  src:  url('fonts/ams-icon.eot?fc6hvd#iefix') format('embedded-opentype'),
    url('fonts/ams-icon.ttf?fc6hvd') format('truetype'),
    url('fonts/ams-icon.woff?fc6hvd') format('woff'),
    url('fonts/ams-icon.svg?fc6hvd#ams-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ams-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ams-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ams-icon-membership-upgrade:before {
  content: "\ec27";
}
.ams-icon-card-view:before {
  content: "\ec26";
}
.ams-icon-blur:before {
  content: "\ec25";
}
.ams-icon-security:before {
  content: "\ec24";
}
.ams-icon-vault:before {
  content: "\ec23";
}
.ams-icon-alphabetAZ:before {
  content: "\ec22";
}
.ams-icon-money-new:before {
  content: "\ec1f";
}
.ams-icon-duration:before {
  content: "\ec20";
}
.ams-icon-follow-up:before {
  content: "\ec21";
}
.ams-icon-poll-disabled:before {
  content: "\ec1e";
}
.ams-icon-gif:before {
  content: "\ec1d";
}
.ams-icon-storage:before {
  content: "\ec1c";
}
.ams-icon-twitter-x:before {
  content: "\ec1b";
}
.ams-icon-saved-replies:before {
  content: "\ec1a";
}
.ams-icon-no-views:before {
  content: "\ec19";
}
.ams-icon-customize:before {
  content: "\ec18";
}
.ams-icon-broom:before {
  content: "\ec17";
}
.ams-icon-text-background:before {
  content: "\ec15";
}
.ams-icon-bullets:before {
  content: "\ec0f";
}
.ams-icon-code:before {
  content: "\ec10";
}
.ams-icon-code-block:before {
  content: "\ec11";
}
.ams-icon-indent-descrease:before {
  content: "\ec12";
}
.ams-icon-indent-increase:before {
  content: "\ec13";
}
.ams-icon-numbering:before {
  content: "\ec14";
}
.ams-icon-text-color:before {
  content: "\ec16";
}
.ams-icon-repost:before {
  content: "\ec0e";
}
.ams-icon-verify-bold:before {
  content: "\ec0d";
}
.ams-icon-custom-domain:before {
  content: "\ec0c";
}
.ams-icon-build-your-site:before {
  content: "\ec0b";
}
.ams-icon-category:before {
  content: "\ec0a";
}
.ams-icon-zip:before {
  content: "\ec08";
}
.ams-icon-audio:before {
  content: "\ec09";
}
.ams-icon-star-outline:before {
  content: "\ec07";
}
.ams-icon-attach-media:before {
  content: "\ec05";
}
.ams-icon-flip:before {
  content: "\ec06";
}
.ams-icon-alignment-center:before {
  content: "\ec00";
}
.ams-icon-alignment-left:before {
  content: "\ec01";
}
.ams-icon-alignment-right:before {
  content: "\ec02";
}
.ams-icon-aspect-ratio:before {
  content: "\ec03";
}
.ams-icon-redo:before {
  content: "\ec04";
}
.ams-icon-unlocked-new:before {
  content: "\ebff";
}
.ams-icon-driver-license:before {
  content: "\ebfb";
}
.ams-icon-id-card:before {
  content: "\ebfc";
}
.ams-icon-passport:before {
  content: "\ebfd";
}
.ams-icon-passport-card:before {
  content: "\ebfe";
}
.ams-icon-light-bulb:before {
  content: "\ebfa";
}
.ams-icon-paper-shredder:before {
  content: "\ebf8";
}
.ams-icon-visibility-lock:before {
  content: "\ebf9";
}
.ams-icon-news:before {
  content: "\ebe3";
}
.ams-icon-account-settings:before {
  content: "\ebdc";
}
.ams-icon-chat-settings:before {
  content: "\ebec";
}
.ams-icon-pencil-new:before {
  content: "\ebf6";
}
.ams-icon-vault-new:before {
  content: "\ebf7";
}
.ams-icon-activity-new:before {
  content: "\ebef";
}
.ams-icon-color-new:before {
  content: "\ebf0";
}
.ams-icon-member-list-new:before {
  content: "\ebf1";
}
.ams-icon-money-outline-new:before {
  content: "\ebf2";
}
.ams-icon-network-new:before {
  content: "\ebf3";
}
.ams-icon-settings-new:before {
  content: "\ebf4";
}
.ams-icon-transactions-new:before {
  content: "\ebf5";
}
.ams-icon-image-outline:before {
  content: "\ebee";
}
.ams-icon-member-list:before {
  content: "\ebed";
}
.ams-icon-cast-members-new:before {
  content: "\ebe6";
}
.ams-icon-chat-new:before {
  content: "\ebe7";
}
.ams-icon-engagement:before {
  content: "\ebe8";
}
.ams-icon-messaging-new:before {
  content: "\ebe9";
}
.ams-icon-revenue:before {
  content: "\ebea";
}
.ams-icon-tag-new:before {
  content: "\ebeb";
}
.ams-icon-acquisition:before {
  content: "\ebdb";
}
.ams-icon-collection:before {
  content: "\ebdd";
}
.ams-icon-desginer:before {
  content: "\ebde";
}
.ams-icon-free-membership:before {
  content: "\ebdf";
}
.ams-icon-info:before {
  content: "\ebe0";
}
.ams-icon-money-outline:before {
  content: "\ebe1";
}
.ams-icon-network:before {
  content: "\ebe2";
}
.ams-icon-retention:before {
  content: "\ebe4";
}
.ams-icon-support-chat:before {
  content: "\ebe5";
}
.ams-icon-backup-code:before {
  content: "\ebd9";
}
.ams-icon-qr-code:before {
  content: "\ebda";
}
.ams-icon-percentage:before {
  content: "\ebd7";
}
.ams-icon-breakdown:before {
  content: "\ebd8";
}
.ams-icon-activity:before {
  content: "\ebd6";
}
.ams-icon-shield:before {
  content: "\ebd4";
}
.ams-icon-shield-outline:before {
  content: "\ebd5";
}
.ams-icon-scan-person:before {
  content: "\ebd3";
}
.ams-icon-unpin-35:before {
  content: "\ebd1";
}
.ams-icon-pin-35:before {
  content: "\ebd2";
}
.ams-icon-message-paid:before {
  content: "\ebcc";
}
.ams-icon-protected:before {
  content: "\ebcd";
}
.ams-icon-play-on:before {
  content: "\ebce";
}
.ams-icon-webhook:before {
  content: "\ebcf";
}
.ams-icon-sign-out:before {
  content: "\ebd0";
}
.ams-icon-upgrade:before {
  content: "\ebcb";
}
.ams-icon-forward:before {
  content: "\ebc7";
}
.ams-icon-sold-out:before {
  content: "\ebc8";
}
.ams-icon-radio-on:before {
  content: "\ebc9";
}
.ams-icon-radio-off:before {
  content: "\ebca";
}
.ams-icon-bundle:before {
  content: "\ebc6";
}
.ams-icon-connect:before {
  content: "\ebc5";
}
.ams-icon-payment-card:before {
  content: "\ebc4";
}
.ams-icon-draft-message:before {
  content: "\ebc3";
}
.ams-icon-new-feedback:before {
  content: "\ebc2";
}
.ams-icon-general:before {
  content: "\ebc1";
}
.ams-icon-cloud-upload-new:before {
  content: "\ebbb";
}
.ams-icon-verify:before {
  content: "\ebc0";
}
.ams-icon-translate:before {
  content: "\ebbf";
}
.ams-icon-domain-redirect:before {
  content: "\ebbd";
}
.ams-icon-launch:before {
  content: "\ebbe";
}
.ams-icon-minus:before {
  content: "\ebbc";
}
.ams-icon-stop:before {
  content: "\ebba";
}
.ams-icon-exit:before {
  content: "\ebb9";
}
.ams-icon-watermark:before {
  content: "\ebad";
}
.ams-icon-throne:before {
  content: "\ebae";
}
.ams-icon-label:before {
  content: "\ebaf";
}
.ams-icon-icons:before {
  content: "\ebb0";
}
.ams-icon-hero:before {
  content: "\ebb1";
}
.ams-icon-header:before {
  content: "\ebb2";
}
.ams-icon-global-settings:before {
  content: "\ebb3";
}
.ams-icon-featured:before {
  content: "\ebb4";
}
.ams-icon-about:before {
  content: "\ebb5";
}
.ams-icon-drag-and-drop:before {
  content: "\ebb6";
}
.ams-icon-button:before {
  content: "\ebb7";
}
.ams-icon-revert:before {
  content: "\ebb8";
}
.ams-icon-fullscreen-exit:before {
  content: "\eba9";
}
.ams-icon-repeat:before {
  content: "\ebaa";
}
.ams-icon-repeat-off:before {
  content: "\ebab";
}
.ams-icon-zoom:before {
  content: "\ebac";
}
.ams-icon-read-mark:before {
  content: "\eba5";
}
.ams-icon-unread-mark:before {
  content: "\eba6";
}
.ams-icon-zoom-in:before {
  content: "\eba7";
}
.ams-icon-zoom-out:before {
  content: "\eba8";
}
.ams-icon-livestream-unlock:before {
  content: "\eba2";
}
.ams-icon-livestream-blocked:before {
  content: "\eba3";
}
.ams-icon-livestream:before {
  content: "\eba4";
}
.ams-icon-filter-new:before {
  content: "\eba1";
}
.ams-icon-location:before {
  content: "\eba0";
}
.ams-icon-member-tag:before {
  content: "\eb9f";
}
.ams-icon-money-2:before {
  content: "\eb9e";
}
.ams-icon-pin:before {
  content: "\eb9a";
}
.ams-icon-unpin:before {
  content: "\eb9c";
}
.ams-icon-apply:before {
  content: "\eb98";
}
.ams-icon-collapse:before {
  content: "\eb74";
}
.ams-icon-custom-variable:before {
  content: "\eb76";
}
.ams-icon-downward:before {
  content: "\eb78";
}
.ams-icon-email-list:before {
  content: "\eb7a";
}
.ams-icon-file:before {
  content: "\eb7c";
}
.ams-icon-picture-in-picture:before {
  content: "\eb82";
}
.ams-icon-plus:before {
  content: "\eb7e";
}
.ams-icon-record:before {
  content: "\eb80";
}
.ams-icon-replace:before {
  content: "\eb84";
}
.ams-icon-stories:before {
  content: "\eb86";
}
.ams-icon-trending:before {
  content: "\eb88";
}
.ams-icon-unarchive:before {
  content: "\eb8a";
}
.ams-icon-unlocked:before {
  content: "\eb8c";
}
.ams-icon-upward:before {
  content: "\eb8e";
}
.ams-icon-upward-left:before {
  content: "\eb90";
}
.ams-icon-vault-v1:before {
  content: "\eb92";
}
.ams-icon-vault-v2:before {
  content: "\eb94";
}
.ams-icon-pdf:before {
  content: "\eb96";
}
.ams-icon-in-app:before {
  content: "\eb6a";
}
.ams-icon-messaging:before {
  content: "\eb6c";
}
.ams-icon-off-notificartion:before {
  content: "\eb6e";
}
.ams-icon-present:before {
  content: "\eb70";
}
.ams-icon-push-notif:before {
  content: "\eb72";
}
.ams-icon-apps:before {
  content: "\eb54";
}
.ams-icon-blocked-camera:before {
  content: "\eb56";
}
.ams-icon-campaign:before {
  content: "\eb58";
}
.ams-icon-config:before {
  content: "\eb5a";
}
.ams-icon-mic:before {
  content: "\eb5c";
}
.ams-icon-offline-stream:before {
  content: "\eb5e";
}
.ams-icon-off-mic:before {
  content: "\eb60";
}
.ams-icon-online-stream:before {
  content: "\eb62";
}
.ams-icon-page:before {
  content: "\eb64";
}
.ams-icon-sign-in:before {
  content: "\eb66";
}
.ams-icon-star:before {
  content: "\eb68";
}
.ams-icon-android:before {
  content: "\eb50";
}
.ams-icon-apple:before {
  content: "\eb4c";
}
.ams-icon-archive:before {
  content: "\eb48";
}
.ams-icon-poll:before {
  content: "\eb44";
}
.ams-icon-promotion:before {
  content: "\eb40";
}
.ams-icon-promotion-ended:before {
  content: "\eb3c";
}
.ams-icon-saved:before {
  content: "\eb38";
}
.ams-icon-select:before {
  content: "\eb34";
}
.ams-icon-sort:before {
  content: "\eb30";
}
.ams-icon-timer:before {
  content: "\eb2c";
}
.ams-icon-amazon:before {
  content: "\eb24";
}
.ams-icon-custom-page:before {
  content: "\eb28";
}
.ams-icon-reply:before {
  content: "\eb20";
}
.ams-icon-copy:before {
  content: "\eb1c";
}
.ams-icon-text-underline:before {
  content: "\eb10";
}
.ams-icon-to-do:before {
  content: "\eb14";
}
.ams-icon-variable:before {
  content: "\eb18";
}
.ams-icon-attach:before {
  content: "\eaec";
}
.ams-icon-attachment:before {
  content: "\eaf0";
}
.ams-icon-text-bold:before {
  content: "\eaf4";
}
.ams-icon-text-center:before {
  content: "\eaf8";
}
.ams-icon-text-heading1:before {
  content: "\eafc";
}
.ams-icon-text-italic:before {
  content: "\eb00";
}
.ams-icon-text-justify:before {
  content: "\eb04";
}
.ams-icon-text-left:before {
  content: "\eb08";
}
.ams-icon-text-right:before {
  content: "\eb0c";
}
.ams-icon-chat-mute:before {
  content: "\eac8";
}
.ams-icon-chat-unlock:before {
  content: "\eacc";
}
.ams-icon-deposit:before {
  content: "\ead0";
}
.ams-icon-greeting:before {
  content: "\ead4";
}
.ams-icon-intercom:before {
  content: "\ead8";
}
.ams-icon-link:before {
  content: "\eadc";
}
.ams-icon-no-internet:before {
  content: "\eae0";
}
.ams-icon-referral:before {
  content: "\eae4";
}
.ams-icon-slack:before {
  content: "\eae8";
}
.ams-icon-download-non:before {
  content: "\eac0";
}
.ams-icon-play-filled-non:before {
  content: "\eac4";
}
.ams-icon-tag:before {
  content: "\eabc";
}
.ams-icon-color-picker:before {
  content: "\eab8";
}
.ams-icon-cast-members:before {
  content: "\ea9c";
}
.ams-icon-checklist:before {
  content: "\eaa0";
}
.ams-icon-desktop:before {
  content: "\eaa4";
}
.ams-icon-membership:before {
  content: "\eaa8";
}
.ams-icon-mobile:before {
  content: "\eaac";
}
.ams-icon-transactions:before {
  content: "\eab0";
}
.ams-icon-wallet:before {
  content: "\eab4";
}
.ams-icon-facebook:before {
  content: "\e961";
}
.ams-icon-fet-life:before {
  content: "\e965";
}
.ams-icon-instagram:before {
  content: "\e969";
}
.ams-icon-snapchat:before {
  content: "\e96d";
}
.ams-icon-twitter:before {
  content: "\e971";
}
.ams-icon-web-site:before {
  content: "\e975";
}
.ams-icon-circle-models:before {
  content: "\ea88";
}
.ams-icon-colors:before {
  content: "\ea8c";
}
.ams-icon-hero-section:before {
  content: "\ea90";
}
.ams-icon-logo:before {
  content: "\ea94";
}
.ams-icon-theme:before {
  content: "\ea98";
}
.ams-icon-attention-outline:before {
  content: "\ea84";
}
.ams-icon-arrow-down:before {
  content: "\ea80";
}
.ams-icon-arrow-up:before {
  content: "\ea7c";
}
.ams-icon-logout:before {
  content: "\ea78";
}
.ams-icon-block:before {
  content: "\ea74";
}
.ams-icon-forum:before {
  content: "\ea70";
}
.ams-icon-money:before {
  content: "\ea6c";
}
.ams-icon-tip:before {
  content: "\ea68";
}
.ams-icon-bug:before {
  content: "\ea54";
}
.ams-icon-dropdown:before {
  content: "\ea58";
}
.ams-icon-feedback:before {
  content: "\ea5c";
}
.ams-icon-help-outline:before {
  content: "\ea60";
}
.ams-icon-live-help:before {
  content: "\ea64";
}
.ams-icon-account:before {
  content: "\e900";
}
.ams-icon-add:before {
  content: "\e904";
}
.ams-icon-add-person:before {
  content: "\e908";
}
.ams-icon-add-photos:before {
  content: "\e90c";
}
.ams-icon-arrow-left:before {
  content: "\e910";
}
.ams-icon-arrow-right:before {
  content: "\e914";
}
.ams-icon-attention:before {
  content: "\e918";
}
.ams-icon-blog:before {
  content: "\e91c";
}
.ams-icon-box-checked:before {
  content: "\e920";
}
.ams-icon-box-unchecked:before {
  content: "\e924";
}
.ams-icon-broken-image:before {
  content: "\e928";
}
.ams-icon-calendar:before {
  content: "\e92c";
}
.ams-icon-cancel:before {
  content: "\e930";
}
.ams-icon-chat:before {
  content: "\e934";
}
.ams-icon-circle-checked:before {
  content: "\e938";
}
.ams-icon-circle-unchecked:before {
  content: "\e93c";
}
.ams-icon-clock:before {
  content: "\e940";
}
.ams-icon-close:before {
  content: "\e944";
}
.ams-icon-comment:before {
  content: "\e948";
}
.ams-icon-content:before {
  content: "\e94c";
}
.ams-icon-create-group:before {
  content: "\e950";
}
.ams-icon-delete:before {
  content: "\e954";
}
.ams-icon-details:before {
  content: "\e958";
}
.ams-icon-done:before {
  content: "\e95c";
}
.ams-icon-download:before {
  content: "\e960";
}
.ams-icon-drag:before {
  content: "\e97c";
}
.ams-icon-emoji:before {
  content: "\e980";
}
.ams-icon-feed:before {
  content: "\e984";
}
.ams-icon-filter:before {
  content: "\e988";
}
.ams-icon-full-screen:before {
  content: "\e98c";
}
.ams-icon-fundraiser:before {
  content: "\e990";
}
.ams-icon-go-to:before {
  content: "\e994";
}
.ams-icon-group:before {
  content: "\e998";
}
.ams-icon-happy:before {
  content: "\e99c";
}
.ams-icon-hd-filled:before {
  content: "\e9a0";
}
.ams-icon-hd-outline:before {
  content: "\e9a4";
}
.ams-icon-heart-filled:before {
  content: "\e9a8";
}
.ams-icon-heart-outline:before {
  content: "\e9ac";
}
.ams-icon-help:before {
  content: "\e9b0";
}
.ams-icon-home:before {
  content: "\e9b4";
}
.ams-icon-image:before {
  content: "\e9b8";
}
.ams-icon-lock:before {
  content: "\e9bc";
}
.ams-icon-menu:before {
  content: "\e9c0";
}
.ams-icon-message:before {
  content: "\e9c4";
}
.ams-icon-minus-one:before {
  content: "\e9c8";
}
.ams-icon-notifications:before {
  content: "\e9cc";
}
.ams-icon-options-vertical:before {
  content: "\e9d0";
}
.ams-icon-optons-horizontal:before {
  content: "\e9d4";
}
.ams-icon-password:before {
  content: "\e9d8";
}
.ams-icon-pause:before {
  content: "\e9dc";
}
.ams-icon-pencil:before {
  content: "\e9e0";
}
.ams-icon-photo-camera:before {
  content: "\e9e4";
}
.ams-icon-photosets:before {
  content: "\e9e8";
}
.ams-icon-play-default:before {
  content: "\e9ec";
}
.ams-icon-play-filled:before {
  content: "\e9f0";
}
.ams-icon-plus-one:before {
  content: "\e9f4";
}
.ams-icon-public:before {
  content: "\e9f8";
}
.ams-icon-retry:before {
  content: "\e9fc";
}
.ams-icon-rotate-crop:before {
  content: "\ea00";
}
.ams-icon-rotate-left:before {
  content: "\ea04";
}
.ams-icon-rotate-right:before {
  content: "\ea08";
}
.ams-icon-sad:before {
  content: "\ea0c";
}
.ams-icon-search:before {
  content: "\ea10";
}
.ams-icon-send:before {
  content: "\ea14";
}
.ams-icon-settings:before {
  content: "\ea18";
}
.ams-icon-several-images:before {
  content: "\ea1c";
}
.ams-icon-share:before {
  content: "\ea20";
}
.ams-icon-sticker:before {
  content: "\ea24";
}
.ams-icon-store:before {
  content: "\ea28";
}
.ams-icon-text:before {
  content: "\ea2c";
}
.ams-icon-unpublish:before {
  content: "\ea30";
}
.ams-icon-upload:before {
  content: "\ea34";
}
.ams-icon-upload-cloud:before {
  content: "\ea38";
}
.ams-icon-video-camera:before {
  content: "\ea3c";
}
.ams-icon-videos:before {
  content: "\ea40";
}
.ams-icon-views:before {
  content: "\ea44";
}
.ams-icon-volume-off:before {
  content: "\ea48";
}
.ams-icon-volume-on:before {
  content: "\ea4c";
}
.ams-icon-warning:before {
  content: "\ea50";
}
.ams-icon-android1:before {
  content: "\eb51";
}
.ams-icon-apple1:before {
  content: "\eb4d";
}
.ams-icon-archive1:before {
  content: "\eb49";
}
.ams-icon-poll1:before {
  content: "\eb45";
}
.ams-icon-promotion1:before {
  content: "\eb41";
}
.ams-icon-promotion-ended1:before {
  content: "\eb3d";
}
.ams-icon-saved1:before {
  content: "\eb39";
}
.ams-icon-select1:before {
  content: "\eb35";
}
.ams-icon-sort1:before {
  content: "\eb31";
}
.ams-icon-timer1:before {
  content: "\eb2d";
}
.ams-icon-amazon1:before {
  content: "\eb25";
}
.ams-icon-custom-page1:before {
  content: "\eb29";
}
.ams-icon-reply1:before {
  content: "\eb21";
}
.ams-icon-copy1:before {
  content: "\eb1d";
}
.ams-icon-text-underline1:before {
  content: "\eb11";
}
.ams-icon-to-do1:before {
  content: "\eb15";
}
.ams-icon-variable1:before {
  content: "\eb19";
}
.ams-icon-attach1:before {
  content: "\eaed";
}
.ams-icon-attachment1:before {
  content: "\eaf1";
}
.ams-icon-text-bold1:before {
  content: "\eaf5";
}
.ams-icon-text-center1:before {
  content: "\eaf9";
}
.ams-icon-text-heading11:before {
  content: "\eafd";
}
.ams-icon-text-italic1:before {
  content: "\eb01";
}
.ams-icon-text-justify1:before {
  content: "\eb05";
}
.ams-icon-text-left1:before {
  content: "\eb09";
}
.ams-icon-text-right1:before {
  content: "\eb0d";
}
.ams-icon-chat-mute1:before {
  content: "\eac9";
}
.ams-icon-chat-unlock1:before {
  content: "\eacd";
}
.ams-icon-deposit1:before {
  content: "\ead1";
}
.ams-icon-greeting1:before {
  content: "\ead5";
}
.ams-icon-intercom1:before {
  content: "\ead9";
}
.ams-icon-link1:before {
  content: "\eadd";
}
.ams-icon-no-internet1:before {
  content: "\eae1";
}
.ams-icon-referral1:before {
  content: "\eae5";
}
.ams-icon-slack1:before {
  content: "\eae9";
}
.ams-icon-download-non1:before {
  content: "\eac1";
}
.ams-icon-play-filled-non1:before {
  content: "\eac5";
}
.ams-icon-tag1:before {
  content: "\eabd";
}
.ams-icon-color-picker1:before {
  content: "\eab9";
}
.ams-icon-cast-members1:before {
  content: "\ea9d";
}
.ams-icon-checklist1:before {
  content: "\eaa1";
}
.ams-icon-desktop1:before {
  content: "\eaa5";
}
.ams-icon-membership1:before {
  content: "\eaa9";
}
.ams-icon-mobile1:before {
  content: "\eaad";
}
.ams-icon-transactions1:before {
  content: "\eab1";
}
.ams-icon-wallet1:before {
  content: "\eab5";
}
.ams-icon-facebook1:before {
  content: "\e962";
}
.ams-icon-fet-life1:before {
  content: "\e966";
}
.ams-icon-instagram1:before {
  content: "\e96a";
}
.ams-icon-snapchat1:before {
  content: "\e96e";
}
.ams-icon-twitter1:before {
  content: "\e972";
}
.ams-icon-web-site1:before {
  content: "\e976";
}
.ams-icon-circle-models1:before {
  content: "\ea89";
}
.ams-icon-colors1:before {
  content: "\ea8d";
}
.ams-icon-hero-section1:before {
  content: "\ea91";
}
.ams-icon-logo1:before {
  content: "\ea95";
}
.ams-icon-theme1:before {
  content: "\ea99";
}
.ams-icon-attention-outline1:before {
  content: "\ea85";
}
.ams-icon-arrow-down1:before {
  content: "\ea81";
}
.ams-icon-arrow-up1:before {
  content: "\ea7d";
}
.ams-icon-logout1:before {
  content: "\ea79";
}
.ams-icon-block1:before {
  content: "\ea75";
}
.ams-icon-forum1:before {
  content: "\ea71";
}
.ams-icon-money1:before {
  content: "\ea6d";
}
.ams-icon-tip1:before {
  content: "\ea69";
}
.ams-icon-bug1:before {
  content: "\ea55";
}
.ams-icon-dropdown1:before {
  content: "\ea59";
}
.ams-icon-feedback1:before {
  content: "\ea5d";
}
.ams-icon-help-outline1:before {
  content: "\ea61";
}
.ams-icon-live-help1:before {
  content: "\ea65";
}
.ams-icon-account1:before {
  content: "\e901";
}
.ams-icon-add1:before {
  content: "\e905";
}
.ams-icon-add-person1:before {
  content: "\e909";
}
.ams-icon-add-photos1:before {
  content: "\e90d";
}
.ams-icon-arrow-left1:before {
  content: "\e911";
}
.ams-icon-arrow-right1:before {
  content: "\e915";
}
.ams-icon-attention1:before {
  content: "\e919";
}
.ams-icon-blog1:before {
  content: "\e91d";
}
.ams-icon-box-checked1:before {
  content: "\e921";
}
.ams-icon-box-unchecked1:before {
  content: "\e925";
}
.ams-icon-broken-image1:before {
  content: "\e929";
}
.ams-icon-calendar1:before {
  content: "\e92d";
}
.ams-icon-cancel1:before {
  content: "\e931";
}
.ams-icon-chat1:before {
  content: "\e935";
}
.ams-icon-circle-checked1:before {
  content: "\e939";
}
.ams-icon-circle-unchecked1:before {
  content: "\e93d";
}
.ams-icon-clock1:before {
  content: "\e941";
}
.ams-icon-close1:before {
  content: "\e945";
}
.ams-icon-comment1:before {
  content: "\e949";
}
.ams-icon-content1:before {
  content: "\e94d";
}
.ams-icon-create-group1:before {
  content: "\e951";
}
.ams-icon-delete1:before {
  content: "\e955";
}
.ams-icon-details1:before {
  content: "\e959";
}
.ams-icon-done1:before {
  content: "\e95d";
}
.ams-icon-download1:before {
  content: "\e979";
}
.ams-icon-drag1:before {
  content: "\e97d";
}
.ams-icon-emoji1:before {
  content: "\e981";
}
.ams-icon-feed1:before {
  content: "\e985";
}
.ams-icon-filter1:before {
  content: "\e989";
}
.ams-icon-full-screen1:before {
  content: "\e98d";
}
.ams-icon-fundraiser1:before {
  content: "\e991";
}
.ams-icon-go-to1:before {
  content: "\e995";
}
.ams-icon-group1:before {
  content: "\e999";
}
.ams-icon-happy1:before {
  content: "\e99d";
}
.ams-icon-hd-filled1:before {
  content: "\e9a1";
}
.ams-icon-hd-outline1:before {
  content: "\e9a5";
}
.ams-icon-heart-filled1:before {
  content: "\e9a9";
}
.ams-icon-heart-outline1:before {
  content: "\e9ad";
}
.ams-icon-help1:before {
  content: "\e9b1";
}
.ams-icon-home1:before {
  content: "\e9b5";
}
.ams-icon-image1:before {
  content: "\e9b9";
}
.ams-icon-lock1:before {
  content: "\e9bd";
}
.ams-icon-menu1:before {
  content: "\e9c1";
}
.ams-icon-message1:before {
  content: "\e9c5";
}
.ams-icon-minus-one1:before {
  content: "\e9c9";
}
.ams-icon-notifications1:before {
  content: "\e9cd";
}
.ams-icon-options-vertical1:before {
  content: "\e9d1";
}
.ams-icon-optons-horizontal1:before {
  content: "\e9d5";
}
.ams-icon-password1:before {
  content: "\e9d9";
}
.ams-icon-pause1:before {
  content: "\e9dd";
}
.ams-icon-pencil1:before {
  content: "\e9e1";
}
.ams-icon-photo-camera1:before {
  content: "\e9e5";
}
.ams-icon-photosets1:before {
  content: "\e9e9";
}
.ams-icon-play-default1:before {
  content: "\e9ed";
}
.ams-icon-play-filled1:before {
  content: "\e9f1";
}
.ams-icon-plus-one1:before {
  content: "\e9f5";
}
.ams-icon-public1:before {
  content: "\e9f9";
}
.ams-icon-retry1:before {
  content: "\e9fd";
}
.ams-icon-rotate-crop1:before {
  content: "\ea01";
}
.ams-icon-rotate-left1:before {
  content: "\ea05";
}
.ams-icon-rotate-right1:before {
  content: "\ea09";
}
.ams-icon-sad1:before {
  content: "\ea0d";
}
.ams-icon-search1:before {
  content: "\ea11";
}
.ams-icon-send1:before {
  content: "\ea15";
}
.ams-icon-settings1:before {
  content: "\ea19";
}
.ams-icon-several-images1:before {
  content: "\ea1d";
}
.ams-icon-share1:before {
  content: "\ea21";
}
.ams-icon-sticker1:before {
  content: "\ea25";
}
.ams-icon-store1:before {
  content: "\ea29";
}
.ams-icon-text1:before {
  content: "\ea2d";
}
.ams-icon-unpublish1:before {
  content: "\ea31";
}
.ams-icon-upload1:before {
  content: "\ea35";
}
.ams-icon-upload-cloud1:before {
  content: "\ea39";
}
.ams-icon-video-camera1:before {
  content: "\ea3d";
}
.ams-icon-videos1:before {
  content: "\ea41";
}
.ams-icon-views1:before {
  content: "\ea45";
}
.ams-icon-volume-off1:before {
  content: "\ea49";
}
.ams-icon-volume-on1:before {
  content: "\ea4d";
}
.ams-icon-warning1:before {
  content: "\ea51";
}
.ams-icon-pin1:before {
  content: "\eb9b";
}
.ams-icon-unpin1:before {
  content: "\eb9d";
}
.ams-icon-apply1:before {
  content: "\eb99";
}
.ams-icon-collapse1:before {
  content: "\eb75";
}
.ams-icon-custom-variable1:before {
  content: "\eb77";
}
.ams-icon-downward1:before {
  content: "\eb79";
}
.ams-icon-email-list1:before {
  content: "\eb7b";
}
.ams-icon-file1:before {
  content: "\eb7d";
}
.ams-icon-picture-in-picture1:before {
  content: "\eb83";
}
.ams-icon-plus1:before {
  content: "\eb7f";
}
.ams-icon-record1:before {
  content: "\eb81";
}
.ams-icon-replace1:before {
  content: "\eb85";
}
.ams-icon-stories1:before {
  content: "\eb87";
}
.ams-icon-trending1:before {
  content: "\eb89";
}
.ams-icon-unarchive1:before {
  content: "\eb8b";
}
.ams-icon-unlocked1:before {
  content: "\eb8d";
}
.ams-icon-upward1:before {
  content: "\eb8f";
}
.ams-icon-upward-left1:before {
  content: "\eb91";
}
.ams-icon-vault-v11:before {
  content: "\eb93";
}
.ams-icon-vault-v21:before {
  content: "\eb95";
}
.ams-icon-pdf1:before {
  content: "\eb97";
}
.ams-icon-in-app1:before {
  content: "\eb6b";
}
.ams-icon-messaging1:before {
  content: "\eb6d";
}
.ams-icon-off-notificartion1:before {
  content: "\eb6f";
}
.ams-icon-present1:before {
  content: "\eb71";
}
.ams-icon-push-notif1:before {
  content: "\eb73";
}
.ams-icon-apps1:before {
  content: "\eb55";
}
.ams-icon-blocked-camera1:before {
  content: "\eb57";
}
.ams-icon-campaign1:before {
  content: "\eb59";
}
.ams-icon-config1:before {
  content: "\eb5b";
}
.ams-icon-mic1:before {
  content: "\eb5d";
}
.ams-icon-offline-stream1:before {
  content: "\eb5f";
}
.ams-icon-off-mic1:before {
  content: "\eb61";
}
.ams-icon-online-stream1:before {
  content: "\eb63";
}
.ams-icon-page1:before {
  content: "\eb65";
}
.ams-icon-sign-in1:before {
  content: "\eb67";
}
.ams-icon-star1:before {
  content: "\eb69";
}
.ams-icon-android2:before {
  content: "\eb52";
}
.ams-icon-apple2:before {
  content: "\eb4e";
}
.ams-icon-archive2:before {
  content: "\eb4a";
}
.ams-icon-poll2:before {
  content: "\eb46";
}
.ams-icon-promotion2:before {
  content: "\eb42";
}
.ams-icon-promotion-ended2:before {
  content: "\eb3e";
}
.ams-icon-saved2:before {
  content: "\eb3a";
}
.ams-icon-select2:before {
  content: "\eb36";
}
.ams-icon-sort2:before {
  content: "\eb32";
}
.ams-icon-timer2:before {
  content: "\eb2e";
}
.ams-icon-amazon2:before {
  content: "\eb26";
}
.ams-icon-custom-page2:before {
  content: "\eb2a";
}
.ams-icon-reply2:before {
  content: "\eb22";
}
.ams-icon-copy2:before {
  content: "\eb1e";
}
.ams-icon-text-underline2:before {
  content: "\eb12";
}
.ams-icon-to-do2:before {
  content: "\eb16";
}
.ams-icon-variable2:before {
  content: "\eb1a";
}
.ams-icon-attach2:before {
  content: "\eaee";
}
.ams-icon-attachment2:before {
  content: "\eaf2";
}
.ams-icon-text-bold2:before {
  content: "\eaf6";
}
.ams-icon-text-center2:before {
  content: "\eafa";
}
.ams-icon-text-heading12:before {
  content: "\eafe";
}
.ams-icon-text-italic2:before {
  content: "\eb02";
}
.ams-icon-text-justify2:before {
  content: "\eb06";
}
.ams-icon-text-left2:before {
  content: "\eb0a";
}
.ams-icon-text-right2:before {
  content: "\eb0e";
}
.ams-icon-chat-mute2:before {
  content: "\eaca";
}
.ams-icon-chat-unlock2:before {
  content: "\eace";
}
.ams-icon-deposit2:before {
  content: "\ead2";
}
.ams-icon-greeting2:before {
  content: "\ead6";
}
.ams-icon-intercom2:before {
  content: "\eada";
}
.ams-icon-link2:before {
  content: "\eade";
}
.ams-icon-no-internet2:before {
  content: "\eae2";
}
.ams-icon-referral2:before {
  content: "\eae6";
}
.ams-icon-slack2:before {
  content: "\eaea";
}
.ams-icon-download-non2:before {
  content: "\eac2";
}
.ams-icon-play-filled-non2:before {
  content: "\eac6";
}
.ams-icon-tag2:before {
  content: "\eabe";
}
.ams-icon-color-picker2:before {
  content: "\eaba";
}
.ams-icon-cast-members2:before {
  content: "\ea9e";
}
.ams-icon-checklist2:before {
  content: "\eaa2";
}
.ams-icon-desktop2:before {
  content: "\eaa6";
}
.ams-icon-membership2:before {
  content: "\eaaa";
}
.ams-icon-mobile2:before {
  content: "\eaae";
}
.ams-icon-transactions2:before {
  content: "\eab2";
}
.ams-icon-wallet2:before {
  content: "\eab6";
}
.ams-icon-facebook2:before {
  content: "\e963";
}
.ams-icon-fet-life2:before {
  content: "\e967";
}
.ams-icon-instagram2:before {
  content: "\e96b";
}
.ams-icon-snapchat2:before {
  content: "\e96f";
}
.ams-icon-twitter2:before {
  content: "\e973";
}
.ams-icon-web-site2:before {
  content: "\e977";
}
.ams-icon-circle-models2:before {
  content: "\ea8a";
}
.ams-icon-colors2:before {
  content: "\ea8e";
}
.ams-icon-hero-section2:before {
  content: "\ea92";
}
.ams-icon-logo2:before {
  content: "\ea96";
}
.ams-icon-theme2:before {
  content: "\ea9a";
}
.ams-icon-attention-outline2:before {
  content: "\ea86";
}
.ams-icon-arrow-down2:before {
  content: "\ea82";
}
.ams-icon-arrow-up2:before {
  content: "\ea7e";
}
.ams-icon-logout2:before {
  content: "\ea7a";
}
.ams-icon-block2:before {
  content: "\ea76";
}
.ams-icon-forum2:before {
  content: "\ea72";
}
.ams-icon-money2:before {
  content: "\ea6e";
}
.ams-icon-tip2:before {
  content: "\ea6a";
}
.ams-icon-bug2:before {
  content: "\ea56";
}
.ams-icon-dropdown2:before {
  content: "\ea5a";
}
.ams-icon-feedback2:before {
  content: "\ea5e";
}
.ams-icon-help-outline2:before {
  content: "\ea62";
}
.ams-icon-live-help2:before {
  content: "\ea66";
}
.ams-icon-account2:before {
  content: "\e902";
}
.ams-icon-add2:before {
  content: "\e906";
}
.ams-icon-add-person2:before {
  content: "\e90a";
}
.ams-icon-add-photos2:before {
  content: "\e90e";
}
.ams-icon-arrow-left2:before {
  content: "\e912";
}
.ams-icon-arrow-right2:before {
  content: "\e916";
}
.ams-icon-attention2:before {
  content: "\e91a";
}
.ams-icon-blog2:before {
  content: "\e91e";
}
.ams-icon-box-checked2:before {
  content: "\e922";
}
.ams-icon-box-unchecked2:before {
  content: "\e926";
}
.ams-icon-broken-image2:before {
  content: "\e92a";
}
.ams-icon-calendar2:before {
  content: "\e92e";
}
.ams-icon-cancel2:before {
  content: "\e932";
}
.ams-icon-chat2:before {
  content: "\e936";
}
.ams-icon-circle-checked2:before {
  content: "\e93a";
}
.ams-icon-circle-unchecked2:before {
  content: "\e93e";
}
.ams-icon-clock2:before {
  content: "\e942";
}
.ams-icon-close2:before {
  content: "\e946";
}
.ams-icon-comment2:before {
  content: "\e94a";
}
.ams-icon-content2:before {
  content: "\e94e";
}
.ams-icon-create-group2:before {
  content: "\e952";
}
.ams-icon-delete2:before {
  content: "\e956";
}
.ams-icon-details2:before {
  content: "\e95a";
}
.ams-icon-done2:before {
  content: "\e95e";
}
.ams-icon-download2:before {
  content: "\e97a";
}
.ams-icon-drag2:before {
  content: "\e97e";
}
.ams-icon-emoji2:before {
  content: "\e982";
}
.ams-icon-feed2:before {
  content: "\e986";
}
.ams-icon-filter2:before {
  content: "\e98a";
}
.ams-icon-full-screen2:before {
  content: "\e98e";
}
.ams-icon-fundraiser2:before {
  content: "\e992";
}
.ams-icon-go-to2:before {
  content: "\e996";
}
.ams-icon-group2:before {
  content: "\e99a";
}
.ams-icon-happy2:before {
  content: "\e99e";
}
.ams-icon-hd-filled2:before {
  content: "\e9a2";
}
.ams-icon-hd-outline2:before {
  content: "\e9a6";
}
.ams-icon-heart-filled2:before {
  content: "\e9aa";
}
.ams-icon-heart-outline2:before {
  content: "\e9ae";
}
.ams-icon-help2:before {
  content: "\e9b2";
}
.ams-icon-home2:before {
  content: "\e9b6";
}
.ams-icon-image2:before {
  content: "\e9ba";
}
.ams-icon-lock2:before {
  content: "\e9be";
}
.ams-icon-menu2:before {
  content: "\e9c2";
}
.ams-icon-message2:before {
  content: "\e9c6";
}
.ams-icon-minus-one2:before {
  content: "\e9ca";
}
.ams-icon-notifications2:before {
  content: "\e9ce";
}
.ams-icon-options-vertical2:before {
  content: "\e9d2";
}
.ams-icon-optons-horizontal2:before {
  content: "\e9d6";
}
.ams-icon-password2:before {
  content: "\e9da";
}
.ams-icon-pause2:before {
  content: "\e9de";
}
.ams-icon-pencil2:before {
  content: "\e9e2";
}
.ams-icon-photo-camera2:before {
  content: "\e9e6";
}
.ams-icon-photosets2:before {
  content: "\e9ea";
}
.ams-icon-play-default2:before {
  content: "\e9ee";
}
.ams-icon-play-filled2:before {
  content: "\e9f2";
}
.ams-icon-plus-one2:before {
  content: "\e9f6";
}
.ams-icon-public2:before {
  content: "\e9fa";
}
.ams-icon-retry2:before {
  content: "\e9fe";
}
.ams-icon-rotate-crop2:before {
  content: "\ea02";
}
.ams-icon-rotate-left2:before {
  content: "\ea06";
}
.ams-icon-rotate-right2:before {
  content: "\ea0a";
}
.ams-icon-sad2:before {
  content: "\ea0e";
}
.ams-icon-search2:before {
  content: "\ea12";
}
.ams-icon-send2:before {
  content: "\ea16";
}
.ams-icon-settings2:before {
  content: "\ea1a";
}
.ams-icon-several-images2:before {
  content: "\ea1e";
}
.ams-icon-share2:before {
  content: "\ea22";
}
.ams-icon-sticker2:before {
  content: "\ea26";
}
.ams-icon-store2:before {
  content: "\ea2a";
}
.ams-icon-text2:before {
  content: "\ea2e";
}
.ams-icon-unpublish2:before {
  content: "\ea32";
}
.ams-icon-upload2:before {
  content: "\ea36";
}
.ams-icon-upload-cloud2:before {
  content: "\ea3a";
}
.ams-icon-video-camera2:before {
  content: "\ea3e";
}
.ams-icon-videos2:before {
  content: "\ea42";
}
.ams-icon-views2:before {
  content: "\ea46";
}
.ams-icon-volume-off2:before {
  content: "\ea4a";
}
.ams-icon-volume-on2:before {
  content: "\ea4e";
}
.ams-icon-warning2:before {
  content: "\ea52";
}
.ams-icon-android11:before {
  content: "\eb53";
}
.ams-icon-apple11:before {
  content: "\eb4f";
}
.ams-icon-archive11:before {
  content: "\eb4b";
}
.ams-icon-poll11:before {
  content: "\eb47";
}
.ams-icon-promotion11:before {
  content: "\eb43";
}
.ams-icon-promotion-ended11:before {
  content: "\eb3f";
}
.ams-icon-saved11:before {
  content: "\eb3b";
}
.ams-icon-select11:before {
  content: "\eb37";
}
.ams-icon-sort11:before {
  content: "\eb33";
}
.ams-icon-timer11:before {
  content: "\eb2f";
}
.ams-icon-amazon11:before {
  content: "\eb27";
}
.ams-icon-custom-page11:before {
  content: "\eb2b";
}
.ams-icon-reply11:before {
  content: "\eb23";
}
.ams-icon-copy11:before {
  content: "\eb1f";
}
.ams-icon-text-underline11:before {
  content: "\eb13";
}
.ams-icon-to-do11:before {
  content: "\eb17";
}
.ams-icon-variable11:before {
  content: "\eb1b";
}
.ams-icon-attach11:before {
  content: "\eaef";
}
.ams-icon-attachment11:before {
  content: "\eaf3";
}
.ams-icon-text-bold11:before {
  content: "\eaf7";
}
.ams-icon-text-center11:before {
  content: "\eafb";
}
.ams-icon-text-heading111:before {
  content: "\eaff";
}
.ams-icon-text-italic11:before {
  content: "\eb03";
}
.ams-icon-text-justify11:before {
  content: "\eb07";
}
.ams-icon-text-left11:before {
  content: "\eb0b";
}
.ams-icon-text-right11:before {
  content: "\eb0f";
}
.ams-icon-chat-mute11:before {
  content: "\eacb";
}
.ams-icon-chat-unlock11:before {
  content: "\eacf";
}
.ams-icon-deposit11:before {
  content: "\ead3";
}
.ams-icon-greeting11:before {
  content: "\ead7";
}
.ams-icon-intercom11:before {
  content: "\eadb";
}
.ams-icon-link11:before {
  content: "\eadf";
}
.ams-icon-no-internet11:before {
  content: "\eae3";
}
.ams-icon-referral11:before {
  content: "\eae7";
}
.ams-icon-slack11:before {
  content: "\eaeb";
}
.ams-icon-download-non11:before {
  content: "\eac3";
}
.ams-icon-play-filled-non11:before {
  content: "\eac7";
}
.ams-icon-tag11:before {
  content: "\eabf";
}
.ams-icon-color-picker11:before {
  content: "\eabb";
}
.ams-icon-cast-members11:before {
  content: "\ea9f";
}
.ams-icon-checklist11:before {
  content: "\eaa3";
}
.ams-icon-desktop11:before {
  content: "\eaa7";
}
.ams-icon-membership11:before {
  content: "\eaab";
}
.ams-icon-mobile11:before {
  content: "\eaaf";
}
.ams-icon-transactions11:before {
  content: "\eab3";
}
.ams-icon-wallet11:before {
  content: "\eab7";
}
.ams-icon-facebook11:before {
  content: "\e964";
}
.ams-icon-fet-life11:before {
  content: "\e968";
}
.ams-icon-instagram11:before {
  content: "\e96c";
}
.ams-icon-snapchat11:before {
  content: "\e970";
}
.ams-icon-twitter11:before {
  content: "\e974";
}
.ams-icon-web-site11:before {
  content: "\e978";
}
.ams-icon-circle-models11:before {
  content: "\ea8b";
}
.ams-icon-colors11:before {
  content: "\ea8f";
}
.ams-icon-hero-section11:before {
  content: "\ea93";
}
.ams-icon-logo11:before {
  content: "\ea97";
}
.ams-icon-theme11:before {
  content: "\ea9b";
}
.ams-icon-attention-outline11:before {
  content: "\ea87";
}
.ams-icon-arrow-down11:before {
  content: "\ea83";
}
.ams-icon-arrow-up11:before {
  content: "\ea7f";
}
.ams-icon-logout11:before {
  content: "\ea7b";
}
.ams-icon-block11:before {
  content: "\ea77";
}
.ams-icon-forum11:before {
  content: "\ea73";
}
.ams-icon-money11:before {
  content: "\ea6f";
}
.ams-icon-tip11:before {
  content: "\ea6b";
}
.ams-icon-bug11:before {
  content: "\ea57";
}
.ams-icon-dropdown11:before {
  content: "\ea5b";
}
.ams-icon-feedback11:before {
  content: "\ea5f";
}
.ams-icon-help-outline11:before {
  content: "\ea63";
}
.ams-icon-live-help11:before {
  content: "\ea67";
}
.ams-icon-account11:before {
  content: "\e903";
}
.ams-icon-add11:before {
  content: "\e907";
}
.ams-icon-add-person11:before {
  content: "\e90b";
}
.ams-icon-add-photos11:before {
  content: "\e90f";
}
.ams-icon-arrow-left11:before {
  content: "\e913";
}
.ams-icon-arrow-right11:before {
  content: "\e917";
}
.ams-icon-attention11:before {
  content: "\e91b";
}
.ams-icon-blog11:before {
  content: "\e91f";
}
.ams-icon-box-checked11:before {
  content: "\e923";
}
.ams-icon-box-unchecked11:before {
  content: "\e927";
}
.ams-icon-broken-image11:before {
  content: "\e92b";
}
.ams-icon-calendar11:before {
  content: "\e92f";
}
.ams-icon-cancel11:before {
  content: "\e933";
}
.ams-icon-chat11:before {
  content: "\e937";
}
.ams-icon-circle-checked11:before {
  content: "\e93b";
}
.ams-icon-circle-unchecked11:before {
  content: "\e93f";
}
.ams-icon-clock11:before {
  content: "\e943";
}
.ams-icon-close11:before {
  content: "\e947";
}
.ams-icon-comment11:before {
  content: "\e94b";
}
.ams-icon-content11:before {
  content: "\e94f";
}
.ams-icon-create-group11:before {
  content: "\e953";
}
.ams-icon-delete11:before {
  content: "\e957";
}
.ams-icon-details11:before {
  content: "\e95b";
}
.ams-icon-done11:before {
  content: "\e95f";
}
.ams-icon-download11:before {
  content: "\e97b";
}
.ams-icon-drag11:before {
  content: "\e97f";
}
.ams-icon-emoji11:before {
  content: "\e983";
}
.ams-icon-feed11:before {
  content: "\e987";
}
.ams-icon-filter11:before {
  content: "\e98b";
}
.ams-icon-full-screen11:before {
  content: "\e98f";
}
.ams-icon-fundraiser11:before {
  content: "\e993";
}
.ams-icon-go-to11:before {
  content: "\e997";
}
.ams-icon-group11:before {
  content: "\e99b";
}
.ams-icon-happy11:before {
  content: "\e99f";
}
.ams-icon-hd-filled11:before {
  content: "\e9a3";
}
.ams-icon-hd-outline11:before {
  content: "\e9a7";
}
.ams-icon-heart-filled11:before {
  content: "\e9ab";
}
.ams-icon-heart-outline11:before {
  content: "\e9af";
}
.ams-icon-help11:before {
  content: "\e9b3";
}
.ams-icon-home11:before {
  content: "\e9b7";
}
.ams-icon-image11:before {
  content: "\e9bb";
}
.ams-icon-lock11:before {
  content: "\e9bf";
}
.ams-icon-menu11:before {
  content: "\e9c3";
}
.ams-icon-message11:before {
  content: "\e9c7";
}
.ams-icon-minus-one11:before {
  content: "\e9cb";
}
.ams-icon-notifications11:before {
  content: "\e9cf";
}
.ams-icon-options-vertical11:before {
  content: "\e9d3";
}
.ams-icon-optons-horizontal11:before {
  content: "\e9d7";
}
.ams-icon-password11:before {
  content: "\e9db";
}
.ams-icon-pause11:before {
  content: "\e9df";
}
.ams-icon-pencil11:before {
  content: "\e9e3";
}
.ams-icon-photo-camera11:before {
  content: "\e9e7";
}
.ams-icon-photosets11:before {
  content: "\e9eb";
}
.ams-icon-play-default11:before {
  content: "\e9ef";
}
.ams-icon-play-filled11:before {
  content: "\e9f3";
}
.ams-icon-plus-one11:before {
  content: "\e9f7";
}
.ams-icon-public11:before {
  content: "\e9fb";
}
.ams-icon-retry11:before {
  content: "\e9ff";
}
.ams-icon-rotate-crop11:before {
  content: "\ea03";
}
.ams-icon-rotate-left11:before {
  content: "\ea07";
}
.ams-icon-rotate-right11:before {
  content: "\ea0b";
}
.ams-icon-sad11:before {
  content: "\ea0f";
}
.ams-icon-search11:before {
  content: "\ea13";
}
.ams-icon-send11:before {
  content: "\ea17";
}
.ams-icon-settings11:before {
  content: "\ea1b";
}
.ams-icon-several-images11:before {
  content: "\ea1f";
}
.ams-icon-share11:before {
  content: "\ea23";
}
.ams-icon-sticker11:before {
  content: "\ea27";
}
.ams-icon-store11:before {
  content: "\ea2b";
}
.ams-icon-text11:before {
  content: "\ea2f";
}
.ams-icon-unpublish11:before {
  content: "\ea33";
}
.ams-icon-upload11:before {
  content: "\ea37";
}
.ams-icon-upload-cloud11:before {
  content: "\ea3b";
}
.ams-icon-video-camera11:before {
  content: "\ea3f";
}
.ams-icon-videos11:before {
  content: "\ea43";
}
.ams-icon-views11:before {
  content: "\ea47";
}
.ams-icon-volume-off11:before {
  content: "\ea4b";
}
.ams-icon-volume-on11:before {
  content: "\ea4f";
}
.ams-icon-warning11:before {
  content: "\ea53";
}
