#modals-root {
   position: fixed;
   z-index: 9;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.modal-content-body {
   max-height: 60vh;
   overflow-y: auto;
}


.animated-up {
   animation-name: modal-in;
   animation-duration: 0.5s;
   animation-timing-function: ease-in;
   animation-fill-mode: forwards;
   animation-delay: 0s;
   animation-direction: reverse;

}

.animated-down {
   animation-name: modal-out;
   animation-duration: 0.8s;
   animation-timing-function: ease-out;
   animation-fill-mode: forwards;
   animation-delay: 0s;
   animation-direction: reverse;
}

.bg-dark-modal {
   background-color: rgba(29, 41, 57, 0.60);
}

@keyframes modal-out {
   0% {
      transform: translateY(100%);
   }

   100% {
      transform: translateY(0%);
   }
}

@-webkit-keyframes modal-out {
   0% {
      -webkit-transform: translateY(100%);
   }

   100% {
      -webkit-transform: translateY(0%);
   }
}

@keyframes modal-in {
   0% {
      transform: translateY(0%);
   }

   100% {
      transform: translateY(100%);
   }
}

@-webkit-keyframes modal-in {
   0% {
      -webkit-transform: translateY(0%);
   }

   100% {
      -webkit-transform: translateY(100%);
   }
}
